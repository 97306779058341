import React, { useState } from 'react';
import { Navbar } from '../components/indexBasicsComponents';
import { useNavigate } from 'react-router-dom';
// ... autres imports nécessaires

function LoginForm() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();
    navigate('/find-a-parking');
    console.log(phoneNumber, password);
  };

  return (
    <div className=" LoginForm ParkingFinderForm Accueil">
        <Navbar isWhite={true} />
        <div className="card shadow-lg rond2">
          <div className="card-header text-center border-0 rond3">
          <svg width="100" height="100" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg" className='ticket-logo my-2'>
                    <path d="M500 250.003C500 388.073 388.071 500 250 500C111.929 500 0 388.073 0 250.003C0 111.934 111.929 0.000235209 250 0.000235209C388.071 0.000235209 500 111.934 500 250.003Z" fill="#003595"/>
                    <path d="M296.064 105.697H160.107V155.809H226.43V155.993H282.733C300.001 155.993 328.909 155.993 328.909 205.174C328.909 253.957 300.001 253.957 282.733 253.957H207.947V253.906H160.107V395.071H210.53V304.24H296.064C346.278 304.24 378.724 265.357 378.724 205.174C378.724 145.675 345.505 105.697 296.064 105.697Z" fill="white"/>
                    <path d="M250.862 226.114H160.1V183.61H250.862V226.114Z" fill="#00AEEF"/>
                </svg>
              <h2 className="card-title">Connexion</h2>
          </div>
          <div className="card-body">
              <form className='px-lg-5 py-lg-2' onSubmit={handleLogin}>
                  <div className="mb-3">
                      <label htmlFor="phoneNumber" className="form-label">Numéro de téléphone</label>
                      <input 
                        type="tel" 
                        className="form-control" 
                        id="phoneNumber" 
                        value={phoneNumber} 
                        onChange={(e) => setPhoneNumber(e.target.value)} 
                        placeholder="123-456-7890"
                      />
                  </div>

                  <div className="mb-4">
                      <label htmlFor="password" className="form-label">Mot de passe</label>
                      <input 
                        type="password" 
                        className="form-control" 
                        id="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Mot de passe"
                      />
                  </div>

                  <div className="text-center">
                      <button type="submit" className="btn btn-primary btn-lg bleu-pema-fonce rond mt-1">
                          Se connecter
                      </button>
                  </div>
              </form>
          </div>
        </div>
    </div>
  );
}

export default LoginForm;
