import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapMarkerAlt, faCalendarAlt, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import '../CSS/ParkingTicket.css';

function ParkingTicket({ parking }) {
  const mapSrc = `https://www.google.com/maps?q=${parking.lat},${parking.lng}&hl=es;z=14&output=embed`;

  return (
    <div className="ticket-container bg-white shadow-sm p-0 rounded h-100">
      <div className="ticket-header d-flex justify-content-between align-items-center">
        <span className="ticket-number">Billet #{parking.id}</span>
        <svg width="80" height="70" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg" className='ticket-logo'>
            <path d="M500 250.003C500 388.073 388.071 500 250 500C111.929 500 0 388.073 0 250.003C0 111.934 111.929 0.000235209 250 0.000235209C388.071 0.000235209 500 111.934 500 250.003Z" fill="#003595"/>
            <path d="M296.064 105.697H160.107V155.809H226.43V155.993H282.733C300.001 155.993 328.909 155.993 328.909 205.174C328.909 253.957 300.001 253.957 282.733 253.957H207.947V253.906H160.107V395.071H210.53V304.24H296.064C346.278 304.24 378.724 265.357 378.724 205.174C378.724 145.675 345.505 105.697 296.064 105.697Z" fill="white"/>
            <path d="M250.862 226.114H160.1V183.61H250.862V226.114Z" fill="#00AEEF"/>
        </svg>
      </div>

      <div className="ticket-body py-3">

        <div className="ticket-map mt-1 d-flex justify-content-center mb-2">
          <img src="https://i.pinimg.com/736x/a8/69/40/a86940a4ed8a69539b341f3c414c47b3.jpg" alt="" className='w-75' />
        </div>
        <h5 className="ticket-title mb-1">1000 de la Gauchetière</h5>

        {/* Date et heure */}
        <div className="d-flex justify-content-center flex-column">
          <div className="date-time ">
            Du
            <FontAwesomeIcon icon={faCalendarAlt} /> <span>18 mai</span>
            <FontAwesomeIcon icon={faClock} /> <span>10H00</span>
          </div>
          <div className="date-time">
            Au
            <FontAwesomeIcon icon={faCalendarAlt} /> <span>18 mai</span>
            <FontAwesomeIcon icon={faClock} /> <span>20H00</span>
          </div>
        </div>



        {/* Prix */}
        <div className="pricing my-3">
          <div className="price-info">
            {/* <FontAwesomeIcon icon={faMoneyBillWave} /> */} <span>Sous Total: 18.00$</span>
          </div>
          <div className="taxes">
            <span>Taxes: 2.70$</span>
          </div>
          <div className="total">
            <strong>Total: 20.70$</strong>
          </div>
        </div>

        {/* Adresse */}
        <div className="address my-3">
          <FontAwesomeIcon icon={faMapMarkerAlt} /> <span>{parking.location}</span>
        </div>

        <div className="ticket-map mt-4">
          {/* <iframe
            title="Google Map"
            src={mapSrc}
            allowFullScreen=""
            loading="lazy"
          ></iframe> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4190.060594249761!2d-73.56640848456246!3d45.49940904335167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91a5d1e3fb39d%3A0x7934c4a99504768b!2s1000%20De%20La%20Gaucheti%C3%A8re%2C%201000%20Rue%20De%20la%20Gaucheti%C3%A8re%20O%2C%20Montr%C3%A9al%2C%20QC%20H3B%204W5!5e0!3m2!1sfr!2sca!4v1715889509995!5m2!1sfr!2sca" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      <div className="ticket-footer text-center">
        <small>Scanner ce code QR à la borne de sortie</small>
      </div>
    </div>
  );
}

export default ParkingTicket;



/* 

        <svg width="100%" height="100" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg" className='ticket-logo'>
                <path d="M500 250.003C500 388.073 388.071 500 250 500C111.929 500 0 388.073 0 250.003C0 111.934 111.929 0.000235209 250 0.000235209C388.071 0.000235209 500 111.934 500 250.003Z" fill="#003595"/>
                <path d="M296.064 105.697H160.107V155.809H226.43V155.993H282.733C300.001 155.993 328.909 155.993 328.909 205.174C328.909 253.957 300.001 253.957 282.733 253.957H207.947V253.906H160.107V395.071H210.53V304.24H296.064C346.278 304.24 378.724 265.357 378.724 205.174C378.724 145.675 345.505 105.697 296.064 105.697Z" fill="white"/>
                <path d="M250.862 226.114H160.1V183.61H250.862V226.114Z" fill="#00AEEF"/>
        </svg>

*/
