import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './traduction/En.json';
import fr from './traduction/Fr.json';

const resources = {
    en: {
    home: en,
    },
    fr: {
    home: fr,
    },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('userLanguage') || 'fr', // Utilisez la langue stockée ou 'en' par défaut
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;