import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking, faArrowRight, faChevronCircleRight, faInfoCircle, faWheelchair, faShieldAlt, faShuttleVan, faVideo, faCar, faWarehouse, faCreditCard, faClock } from '@fortawesome/free-solid-svg-icons';
import '../CSS/ParkingCard.css';
import { Modal, Button } from 'react-bootstrap';

function ParkingCard({ parking }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    // Carte des attributs pour la correspondance des numéros aux caractéristiques
    const ATTRIBUTES_MAP = {
      1: "Sécurité 24/7",
      2: "Navette gratuite",
      3: "Lavage de voiture disponible",
      4: "Couvert",
      5: "Surveillance vidéo",
      6: "Ouvert 24h",
      7: "Prépaiement en ligne",
      8: "Stationnement à long terme",
      9: "Accessible aux handicapés"
  };

  // Carte des icônes pour les caractéristiques
  const ATTRIBUTES_ICONS = {
    1: faShieldAlt,
    2: faShuttleVan,
    3: faCar,
    4: faWarehouse,
    5: faVideo,
    6: faClock,
    7: faCreditCard,
    8: faWarehouse,
    9: faWheelchair
  };

  const goCheckout = (parkingId) => {
    navigate(`/checkout/${parkingId}`);
  }

  let attributsList = Array.isArray(parking.attributs) ? parking.attributs : [];

  return (
    <div className="col-12 mb-4 p-0 p-1 ParkingCard">
      <div className="card shadow h-100 d-flex flex-row rond2 overflow-hidden">
        <img src={parking.imageLink} className="card-img-left custom-img-horizontal" alt={parking.nom} />
        <div className="card-body d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center mb-2">
              <h5 className="card-title card-title-custom">{parking.nom}</h5>
              <Button variant="link" className="p-0" onClick={handleShow}>
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
              </Button>
          </div>
              <span className="badge bleu fs-6 d-block text-left">{parking.prix}$</span>
          <div className="">
            <p><strong>Heure d'accès d'entrée:</strong> 8h AM</p>
            <p><strong>Durée:</strong> {parking.duree}</p>
            <p><strong>Valide jusqu'au:</strong> {parking.valide}</p>
          </div>
          <p className="text-secondary">
            {parking.adresse} <br/> 
           <a href='https://www.google.com/maps/place/1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5/@45.499409,-73.5664085,16.42z/data=!4m15!1m8!3m7!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!2s1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5!3b1!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr!3m5!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr?entry=ttu'>Voir sur la carte <FontAwesomeIcon icon={faArrowRight} /></a></p>
          <button className="btn bleu-pema-fonce mt-auto text-white rond2 " onClick={() => goCheckout(parking.id)}>
            Prépayé <FontAwesomeIcon icon={faChevronCircleRight} />
          </button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Informations supplémentaires</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-group list-group-flush">
            {attributsList.map((attributNum) => (
              <li key={attributNum} className="list-group-item">
                <FontAwesomeIcon icon={ATTRIBUTES_ICONS[attributNum]} fixedWidth />{" "}
                {ATTRIBUTES_MAP[attributNum] || "Attribut inconnu"}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
              type="button"
              className="btn btn-primary bleu-pema-fonce"
              onClick={handleClose}
              >
              Fermer 
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ParkingCard;
