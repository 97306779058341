import React from 'react';
import ParkingFinderForm from '../components/ParkingFinderForm';
import { Navbar } from '../components/indexBasicsComponents';
import '../CSS/Accueil.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Accueil() {
  return (
    <div className='Accueil '>
    <Navbar isWhite={true}/>
      <div className="content">
        <ParkingFinderForm/>
      </div>
    </div>
  )
}

export default Accueil