import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { fr } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import '../CSS/ParkingFinderForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faCalendarDays, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import ParkingCard from './ParkingCard';
import 'bootstrap/dist/css/bootstrap.min.css';


function ParkingFinderForm() {
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('12:00');

    const [startDate, setStartDate] = useState(new Date());
    const [exitDate, setExitDate] = useState(new Date());


    const [parkings, setParkings] = useState([]);

    const times = Array.from(new Array(24 * 2)).map(
        (_, index) => `${String(Math.floor(index / 2)).padStart(2, '0')}:${index % 2 === 0 ? '00' : '30'}`
    );

    const timeOptions = times.map(time => ({ value: time, label: time }));

    const findParking = (startDate, startTime, endTime, exitDate) => {
        setParkings([
            {
                id: 1, 
                nom: "1000 De La Gauchetière", 
                adresse: "1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5", 
                imageLink: "https://img.lapresse.ca/924x615/201705/12/1401641-caisse-depot-mettra-bientot-vente.jpg", 
                attributs: [1, 3, 6], 
                fromAirport: 4, 
                prix: "20.70",
                startDate: "16 Mai",
                exitDate: "18 Mai",
                duree: "10 heures",
                valide: "18 mai",
                lat: 45.49817526069414, 
                lng: -73.56637633437992
            }
            /* {
                id:2,
                nom:"Parking 3",
                adresse:"Adresse 3",
                imageLink:"https://www.lefilips.com/DATA/NOUVELLE/21_nouvelle.jpg",
                attributs:[7,4,9],
                fromAirport:4,
                prix:"55.90"
            },
            {
                id:0,
                nom:"Parking 1",
                adresse:"Adresse 1",
                imageLink:"https://images.unsplash.com/photo-1588362951121-3ee319b018b2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                attributs:[2,5,8],
                fromAirport:2,
                prix:"60"
            } */
        ]);
    };

    return (
      <div className="container my-5 ParkingFinderForm">
          <div className="card shadow-lg rond2">
            <div className="card-header text-center border-0">
                <svg width="80" height="70" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg" className='ticket-logo my-2'>
                    <path d="M500 250.003C500 388.073 388.071 500 250 500C111.929 500 0 388.073 0 250.003C0 111.934 111.929 0.000235209 250 0.000235209C388.071 0.000235209 500 111.934 500 250.003Z" fill="#003595"/>
                    <path d="M296.064 105.697H160.107V155.809H226.43V155.993H282.733C300.001 155.993 328.909 155.993 328.909 205.174C328.909 253.957 300.001 253.957 282.733 253.957H207.947V253.906H160.107V395.071H210.53V304.24H296.064C346.278 304.24 378.724 265.357 378.724 205.174C378.724 145.675 345.505 105.697 296.064 105.697Z" fill="white"/>
                    <path d="M250.862 226.114H160.1V183.61H250.862V226.114Z" fill="#00AEEF"/>
                </svg>
                <h2 className="card-title">Réserver ma place de stationnement</h2>
                <p className='card-undertitle'><FontAwesomeIcon icon={faMapMarkerAlt} /> 1000 de la Gauchetière, Montréal</p>
                {/* <p className='monthly-btn'>Mensuel</p> */}
            </div>
            <div className="card-body">
                <form className='px-lg-5 py-lg-2'>
                    <div className="row g-3">

                        {/* <div className="col-12 my-2">
                            <label htmlFor="lieu" className="form-label">Lieu</label>
                            <input type="text" className="form-control" id="lieu" placeholder='Emplacement actuel' />
                        </div> */}

                        <div className="col-12 col-md-6 my-2">
                            <label htmlFor="entryDate" className="form-label bleu">Date d'entrée</label>
                            <div className="input-group">
                            <span className="input-group-text"><FontAwesomeIcon icon={faCalendarDays} /></span>
                            <DatePicker
                                id="entryDate"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                locale={fr}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                            </div>
                        </div>

                        {/* <div className="col-md-6 my-2">
                            <label htmlFor="startTime" className="form-label">Heure d'entrée</label>
                            <Select
                                id="startTime"
                                options={timeOptions}
                                value={{ value: startTime, label: startTime }}
                                onChange={option => setStartTime(option.value)}
                                className="basic-single"
                                classNamePrefix="select"
                            />
                        </div> */}

                        {/* <div className="col-md-6 my-2">
                            <label htmlFor="exitDate" className="form-label">Date de sortie</label>
                            <div className="input-group">
                                <span className="input-group-text"><FontAwesomeIcon icon={faCalendarDays} /></span>

                            </div>
                        </div> */}

                        {/* <div className="col-md-6 my-2">
                            <label htmlFor="endTime" className="form-label">Heure de sortie</label>
                            <Select
                                id="endTime"
                                options={timeOptions}
                                value={{ value: endTime, label: endTime }}
                                onChange={option => setEndTime(option.value)}
                                className="basic-single"
                                classNamePrefix="select"
                            />
                        </div> */}

                        <div className="col-12 col-md-6 my-2">
                            <label htmlFor="promoCode" className="form-label bleu">Code Promo</label>
                            <input type="text" className="form-control" id="promoCode" placeholder="Entrez votre code promo" />
                        </div>

                        <div className="col-12 text-center">
                        <button
                            type="button"
                            className="btn btn-primary btn-lg bleu-pema-fonce rond mt-1"
                            onClick={() => findParking(startDate, startTime, endTime, exitDate)}
                            >
                            Obtenir un prix <FontAwesomeIcon icon={faChevronCircleRight} />
                        </button>
                            {/* <button type="button" className="btn btn-primary btn-lg bleu-pema-fonce rond mt-1" onClick={() => findParking(startDate, startTime, endTime, exitDate)}>
                                Obtenir un prix <FontAwesomeIcon icon={faChevronCircleRight} />
                            </button> */}
                        </div>
                    </div>
                </form>
            </div>
          </div>

          {/* Ici, vous pourriez afficher les cartes de stationnement disponibles en fonction de la recherche */}
          <div className="container">
            <div className="row mt-3">
                {parkings.map((parking) => (
                    <ParkingCard key={parking.id} parking={parking} />
                ))}
            </div>
          </div>
      </div>
  );
}

export default ParkingFinderForm;
