import React, {useEffect, useState} from 'react';
import { Navbar } from '../components/indexBasicsComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faClock, faPlusCircle, faStopCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Modal, Button } from 'reactstrap';
import '../CSS/EditPayment.css';

function EditPayment() {

    const [modal, setModal] = useState(true);
    const [ticketNumber, setTicketNumber] = useState('');

    const toggleModal = () => {
        setModal(!modal);
      };


    const handleTicketNumberSubmit = () => {
    // Ici, vous pourriez faire un appel API pour récupérer les détails du parking
    // en utilisant le numéro de billet, puis fermer la modale.
    // Pour cet exemple, nous allons simplement fermer la modale.
    console.log("Ticket number entered:", ticketNumber);
    toggleModal();
    };

    // Simulation des données de parking
    const parkingData = {
        id: 'P12345',
        slot: 'A2',
        arriveTime: new Date(new Date().setHours(new Date().getHours() + 1)).toISOString(), // Arrive dans 1 heure
        exitTime: new Date(new Date().setHours(new Date().getHours() + 2)).toISOString(), // Sortie dans 5 heures
    };

    // Convertir les chaînes ISO en objets Date
    const arriveTime = new Date(parkingData.arriveTime);
    const exitTime = new Date(parkingData.exitTime);

    // Calculer la durée totale
    const totalTime = exitTime - arriveTime;
    const [timeLeft, setTimeLeft] = useState(totalTime);
    const [percentage, setPercentage] = useState((timeLeft / totalTime) * 100);

    // Formatter les dates en français
    const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    
    // Détails du parking pour l'affichage
    const parkingDetails = {
        slot: parkingData.slot,
        startTime: dateFormatter.format(arriveTime),
        endTime: dateFormatter.format(exitTime),
        location: "1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5"
    };

    function formatTimeLeft(time) {
        const hours = Math.floor(time / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      }

      // Mise à jour du compte à rebours et de la barre de progression
    useEffect(() => {
        const interval = setInterval(() => {
        setTimeLeft(prevTimeLeft => {
            const updatedTimeLeft = prevTimeLeft - 1000;
            if (updatedTimeLeft < 0) {
            clearInterval(interval);
            return 0;
            }
            setPercentage((updatedTimeLeft / totalTime) * 100);
            return updatedTimeLeft;
        });
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    // Affichage du temps restant dans le format hh:mm:ss
    const formattedTimeLeft = formatTimeLeft(timeLeft);

  return (
    <div className="EditPayment p-2 height-100vh">
        <Navbar isWhite={true} />

        <Modal isOpen={modal} toggle={toggleModal} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Saisir le numéro du ticket de parking</h5>
          <button type="button" className="btn-close" onClick={toggleModal}></button>
        </div>
        <div className="modal-body">
          <input 
            type="text" 
            className="form-control" 
            placeholder="Numéro du billet" 
            value={ticketNumber}
            onChange={(e) => setTicketNumber(e.target.value)} 
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={handleTicketNumberSubmit}>Soumettre</Button>
        </div>
      </Modal>
      {!modal &&
        <div className="text-center border-0 pt-4 d-flex flex-column align-items-center">
            <div className="card-header border-0">
            <h3>Session en cours</h3>
            </div>
            <div className="">
                <div style={{ width: '200px', height: '200px', margin: 'auto' }} className='progress-bar-container mb-3'>
                    <CircularProgressbar
                    value={percentage}
                    styles={buildStyles({
                        pathColor: `rgba(0, 53, 149, ${percentage / 100})`,
                        textColor: '#4e4e4e',
                        trailColor: '#009fe3',
                        backgroundColor: '#3e98c7',
                    })}
                    />
                    <img src="https://png.pngtree.com/png-vector/20230408/ourmid/pngtree-white-car-top-view-vector-png-image_6681668.png" alt="" className='car-icon' />
                </div>
                <h2 className="timer">{formattedTimeLeft}</h2>
                <p>Durée restante</p>
            
                <div className="session-details my-4">
                    <div className='d-flex justify-content-center'>
                        <p className='slot'>Emplacement {parkingDetails.slot}</p>
                    </div>
                    <div className="d-flex justify-content-between my-2">
                        <p><span className='label'>Arrivé</span> <br/>{parkingDetails.startTime}</p>
                        <p><span className='label'>Départ</span> <br/>{parkingDetails.endTime}</p>
                    </div>
                    <p><FontAwesomeIcon icon={faMapMarkerAlt} /> {parkingDetails.location}</p>
                    <button className="info btn bleu-pema-fonce text-white"> Localiser mon stationnement <FontAwesomeIcon icon={faCar} /></button>
                </div>

                <button className="mx-2 btn btn-success"><FontAwesomeIcon icon={faPlusCircle} /> Prolonger ma session</button>
                {/* <button className="mx-2 btn btn-danger"><FontAwesomeIcon icon={faStopCircle} /> End Parking</button> */}
            </div>
            {/* <div className="card-footer border-0">

            </div> */}
        </div>
      }
    </div>
  )
}

export default EditPayment