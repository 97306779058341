import React, { useState, useEffect } from 'react';
import { Navbar } from '../components/indexBasicsComponents';
import '../CSS/Checkout.css';
import {ParkingTicket} from '../components/indexComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcPaypal } from '@fortawesome/free-brands-svg-icons';

function Checkout() {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [total, setTotal] = useState(0);
  const [cardType, setCardType] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvv, setCvv] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const parking = {id:52415, name:"parking 1", adresse:"Adresse 1", imageLink:"https://images.unsplash.com/photo-1588362951121-3ee319b018b2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", attributs:[2,5,8], fromAirport:2, price:"56", lng:"2.2945", lat:"48.8584", taxes: "8.40", serviceHours: "2 février 13h - 5 février 16h", location:"1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5", startDate: "2 février", startTime: "13h00", endDate: "5 février", endTime: "16h00" };

  const fetchTotal = () => {
    // Simulez un appel API pour obtenir le total
    const fakeTotal = 160.00; // Total fictif
    setTotal(fakeTotal);
  };

  useEffect(() => {
    fetchTotal();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Simulez un processus de paiement
    setTimeout(() => {
      setIsLoading(false);
      setPaymentConfirmed(true);
      setShowPaymentForm(false); // Cache le formulaire après la confirmation du paiement
      // Intégration de l'appel à l'API de paiement réel à faire ici
      console.log("Payment confirmed, sending invoice to:", email);
    }, 2000); // Simulez un délai de 2 secondes
    // Gestion de la soumission du formulaire
    console.log("Email:", email, "Total:", total, "Card Type:", cardType, "Card Number:", cardNumber, "Expiry Date:", expiry, "CVV:", cvv);
  };

  return (
        <div className="test">
        <Navbar isWhite={true}/>
  
        <div className="row mt-navbar p-3 h-100 m-1 m-lg-0">
          <div className="col-md-6 bg-white rounded p-sm-5 mb-5 mb-lg-0">
            {showPaymentForm ? (
              <form onSubmit={handleSubmit} className='bg-white p-3 rounded'>
                  <svg width="80" height="70" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg" className='ticket-logo my-2'>
                    <path d="M500 250.003C500 388.073 388.071 500 250 500C111.929 500 0 388.073 0 250.003C0 111.934 111.929 0.000235209 250 0.000235209C388.071 0.000235209 500 111.934 500 250.003Z" fill="#003595"/>
                    <path d="M296.064 105.697H160.107V155.809H226.43V155.993H282.733C300.001 155.993 328.909 155.993 328.909 205.174C328.909 253.957 300.001 253.957 282.733 253.957H207.947V253.906H160.107V395.071H210.53V304.24H296.064C346.278 304.24 378.724 265.357 378.724 205.174C378.724 145.675 345.505 105.697 296.064 105.697Z" fill="white"/>
                    <path d="M250.862 226.114H160.1V183.61H250.862V226.114Z" fill="#00AEEF"/>
                </svg>
                <h2>Détails du paiement</h2>
                <p>Terminez votre achat en fournissant les détails du paiement.</p>
  
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Courriel</label>
                  <input 
                    type="email" 
                    className="form-control" 
                    id="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Entrez votre courriel" 
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">Téléphone</label>
                  <input 
                    type="tel" 
                    className="form-control" 
                    id="phoneNumber" 
                    value={phoneNumber} 
                    onChange={(e) => setPhoneNumber(e.target.value)} 
                    placeholder="123-456-7890" 
                  />
                </div>

                <div className="payment-methods my-3 w-100">
                        <span className='mb-2 d-block'>Sélectionner le mode de paiement</span>
                        <div className="d-flex justify-content-left align-items-center">
                            <FontAwesomeIcon icon={faCcVisa} size="3x" className="mx-2" />
                            <FontAwesomeIcon icon={faCcMastercard} size="3x" className="mx-2" />
                            <FontAwesomeIcon icon={faCcPaypal} size="3x" className="mx-2" />
                        </div>
                    </div>

                <div className="mb-3">
                  <label htmlFor="cardType" className="form-label">Type de carte</label>
                  <select 
                    className="form-select" 
                    id="cardType" 
                    value={cardType} 
                    onChange={(e) => setCardType(e.target.value)}
                  >
                    <option value="">Sélectionner le type de carte</option>
                    <option value="Visa">Visa</option>
                    <option value="MasterCard">MasterCard</option>
                    <option value="American Express">American Express</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="cardNumber" className="form-label">Numéro de la carte</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="cardNumber" 
                    value={cardNumber} 
                    onChange={(e) => setCardNumber(e.target.value)} 
                    placeholder="Numéro de la carte" 
                  />
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="expiry" className="form-label">Expiration</label>
                    <input 
                      type="text" 
                      className="form-control" 
                      id="expiry" 
                      value={expiry} 
                      onChange={(e) => setExpiry(e.target.value)} 
                      placeholder="MM/AA" 
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="cvv" className="form-label">CVV</label>
                    <input 
                      type="text" 
                      className="form-control" 
                      id="cvv" 
                      value={cvv} 
                      onChange={(e) => setCvv(e.target.value)} 
                      placeholder="CVV" 
                    />
                  </div>
                </div>

                <div className="d-grid gap-2">
                  <button className="btn bleu-pema-fonce text-white" type="submit" disabled={isLoading}>
                    {isLoading ? 'Chargement...' : `Payer 20.70$`}
                  </button>
                  {/* <button className="btn bleu-pema-pale text-white" type="submit" disabled={isLoading}>
                    {isLoading ? 'Chargement...' : `Payer à la sortie`}
                  </button> */}
                </div>
  
                {paymentConfirmed && (
                  <div className="alert alert-success mt-3" role="alert">
                    Paiement réussi ! Une copie de la facture a été envoyée à votre adresse électronique.
                  </div>
                )}
  
                <div className="mt-3 mb-5">
                  <small>Le paiement est sécurisé et crypté.</small>
                </div>
              </form>
            ) : (
              <div className='d-flex flex-column gap-2 p-2 '>
                <button className="btn btn-secondary bleu-pema-pale" onClick={() => setShowPaymentForm(true)}>
                  Modifier le paiement
                </button>
                <button className="btn btn-primary bleu-pema-fonce">
                  Voir l'itinéraire
                </button>
                {paymentConfirmed && (
                  <div className="alert alert-success mt-3" role="alert">
                   Paiement réussi ! Une copie de la facture a été envoyée à votre adresse électronique.
                  </div>
                )}
              </div>
            )}
          </div>

          <ParkingTicket parking={parking}/>

        </div>
      </div>
  );
}

export default Checkout;