import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Accueil, Checkout, EditPayment, LoginForm } from './pages/indexPages';


function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginForm/>}/>
      <Route path="/" element={<Accueil/>}/>
      <Route path="/checkout/:id" element={<Checkout/>}/>
      <Route path="/edit" element={<EditPayment/>}/>
      
      {/* Ajoutez ici les autres routes pour les nouvelles pages */}
    </Routes>
  );
}

export default App;